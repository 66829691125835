import { useEffect, useState } from 'react';
import CategoryGrid from '../components/Category/CategoryGrid';
import LoadingBar from '../components/Category/LoadingBar';
import useHttp from '../hooks/use-http';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PromptList from '../components/Category/PromptList';
import Layout from '../components/Layout/Layout';
import { useSelector } from 'react-redux';

interface SubSubCategory {
  _id: string;
  icon: string;
  title: string;
  totalPrompts: number;
}

interface Prompt {
  _id: string;
  text: string;
}

interface BreadcrubLink {
  text: string;
  link: string;
}

const SubSubCategory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [subSubCategoryList, setSubSubCategoryList] = useState<
    SubSubCategory[] | []
  >([]);
  const [promptsList, setPromptsList] = useState<Prompt[] | []>([]);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<BreadcrubLink[]>([
    { text: 'Home', link: '/mc' },
  ]);
  const { sendRequest, isLoading, error } = useHttp();
  const user = useSelector((state: any) => state.user);

  const getSubSubCategories = async () => {
    const reqConfig = {
      method: 'POST',
      url:
        process.env.REACT_APP_BACKEND_ADDRESS +
        '/sub-sub-category/by-subcategory/' +
        params.subCategoryId,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {
        userId: user.id,
      },
    };

    const response = await sendRequest(reqConfig);
    if (response?.response?.status === 403) {
      navigate('/');
    } else {
      setSubSubCategoryList(response.data.data.subSubCategories.reverse());
      setPromptsList(response.data.data.prompts.reverse());
      setBreadcrumbLinks((prev) => [
        ...prev,
        {
          text: response.data.data.categoryTitle,
          link: '/sc/' + response.data.data.category,
        },
        {
          text: response.data.data.title,
          link: '/ssc/' + response.data.data._id,
        },
      ]);
    }
  };

  useEffect(() => {
    getSubSubCategories();
  }, []);

  const handleCategorySelection = (link: string) => {
    navigate(link);
  };

  const handlePromptSelection = (promptId: string) => {
    navigate(`/c/${promptId}`);
  };

  const handleResults = (result: any) => {
    setSubSubCategoryList([
      ...result.categories,
      ...result.subCategories,
      ...result.subSubCategories,
    ]);
    setPromptsList([...result.prompts]);
  };

  const handleResetSearch = () => {
    getSubSubCategories();
    setPromptsList([]);
    setBreadcrumbLinks([{ text: 'Home', link: '/mc' }]);
  };

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      searchResult={handleResults}
      onResetSearch={handleResetSearch}
    >
      <LoadingBar loading={isLoading} />
      <CategoryGrid
        categoryList={subSubCategoryList}
        categoryChange={handleCategorySelection}
      />
      <PromptList
        promptsList={promptsList}
        onPromptSelect={handlePromptSelection}
      />
    </Layout>
  );
};

export default SubSubCategory;
