import { useEffect, useState } from 'react';
import CategoryGrid from '../components/Category/CategoryGrid';
import LoadingBar from '../components/Category/LoadingBar';
import useHttp from '../hooks/use-http';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import PromptList from '../components/Category/PromptList';
import { useSelector } from 'react-redux';

interface Category {
  _id: string;
  icon: string;
  title: string;
  totalPrompts: number;
}

interface Prompt {
  _id: string;
  text: string;
}
interface BreadcrubLink {
  text: string;
  link: string;
}

const Category = () => {
  const [categoryList, setCategoryList] = useState<Category[] | []>([]);
  const [promptsList, setPromptsList] = useState<Prompt[] | []>([]);
  const [breadcrumbLinks] = useState<BreadcrubLink[]>([
    { text: 'Home', link: '/mc' },
  ]);
  const { sendRequest, isLoading, error } = useHttp();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const getCategories = async () => {
    const reqConfig = {
      method: 'GET',
      url:
        process.env.REACT_APP_BACKEND_ADDRESS + '/category/with-total-prompts',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    };

    const response = await sendRequest(reqConfig);

    if (response?.response?.status === 403) {
      navigate('/');
    } else {
      setCategoryList(response.data.data.reverse());
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategorySelection = (link: string) => {
    navigate(link);
  };

  const handlePromptSelection = (promptId: string) => {
    navigate(`/c/${promptId}`);
  };

  const handleResults = (result: any) => {
    setCategoryList([
      ...result.categories,
      ...result.subCategories,
      ...result.subSubCategories,
    ]);
    setPromptsList([...result.prompts]);
  };

  const handleResetSearch = () => {
    getCategories();
    setPromptsList([]);
  };

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      searchResult={handleResults}
      onResetSearch={handleResetSearch}
    >
      <LoadingBar loading={isLoading} />
      <CategoryGrid
        categoryList={categoryList}
        categoryChange={handleCategorySelection}
      />
      <PromptList
        promptsList={promptsList}
        onPromptSelect={handlePromptSelection}
      />
    </Layout>
  );
};

export default Category;
