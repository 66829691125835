import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  id?: string;
  sx?: {};
  children?: ReactNode;
  startIcon?: ReactNode | string;
  endIcon?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  colorGradient?: string;
  inActive?: boolean;
  invisible?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = (props) => {
  const sxObj = {
    backgroundImage:
      props.colorGradient === 'horizontal'
        ? 'linear-gradient(to right, #D1603C, #981AAC)'
        : 'linear-gradient(#D1603C, #981AAC)',
    backgroundColor: 'none',
    color: 'white',
    borderRadius: '999px',
    padding: '0px 25px',
    height: '38px',
    border: 'none',
    textTransform: 'capitalize',
    flexShrink: 0,
    visibility: 'none',
    '&:hover': {
      color: 'white',
    },
    ...props.sx,
  };

  if (props.inActive) {
    sxObj.backgroundImage = 'none';
    sxObj.backgroundColor = '#D9D9D9';
    sxObj.color = '#262729';
  }

  if (props.invisible) {
    sxObj.visibility = 'hidden';
  }

  return (
    <Button
      id={props.id}
      sx={sxObj}
      startIcon={
        props.loading ? (
          <CircularProgress size='1rem' sx={{ color: '#262729' }} />
        ) : (
          props.startIcon
        )
      }
      endIcon={props.endIcon}
      onClick={props.onClick}
      variant='outlined'
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
