import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CustomButton from '../UI/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useHttp from '../../hooks/use-http';
import { useSelector } from 'react-redux';

interface Props {
  promptId?: string;
  disabled?: boolean;
  onSubmit: () => void;
  selectedPrompt: string;
  onPromptChange: (prompt: string) => void;
}

const Input: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isCopied, setIsCopied] = useState(false);
  const { sendRequest, isLoading, error } = useHttp();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isCopied]);

  const handleCopyToClipboard = async () => {
    navigator.clipboard.writeText(props.selectedPrompt);
    setIsCopied(true);

    const reqConfig = {
      method: 'PATCH',
      url:
        process.env.REACT_APP_BACKEND_ADDRESS +
        '/prompt/used/' +
        props.promptId,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    };

    const response = await sendRequest(reqConfig);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      props.onSubmit();
    }
  };

  return (
    <>
      <TextField
        inputProps={{
          style: {
            fontSize: isMobile ? '16px' : '24px',
            lineHeight: isMobile ? '19px' : '29px',
            fontWeight: '400',
          },
        }}
        id='outlined-multiline-static'
        multiline
        maxRows={isMobile ? 30 : 20}
        fullWidth
        value={props.selectedPrompt}
        onChange={(e) => props.onPromptChange(e.target.value)}
        sx={{
          backgroundColor: '#DEE3EB',
          borderRadius: '5px',
        }}
        onKeyDown={handleKeyDown}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'flex-end',
          gap: '22px',
        }}
      >
        <CustomButton
          startIcon={<ContentCopyIcon />}
          colorGradient='horizontal'
          onClick={handleCopyToClipboard}
        >
          {isCopied ? 'Copied' : 'Copy To Clipboard'}
        </CustomButton>
        {!props.disabled && (
          <CustomButton
            onClick={props.onSubmit}
            startIcon={<SendIcon />}
            colorGradient='horizontal'
          >
            Submit
          </CustomButton>
        )}
      </Box>
    </>
  );
};

export default Input;
