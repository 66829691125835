import { useEffect, useState } from 'react';
import LoadingBar from '../components/Category/LoadingBar';
import useHttp from '../hooks/use-http';
import { useParams } from 'react-router-dom';
import PromptList from '../components/Category/PromptList';
import Layout from '../components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import CategoryGrid from '../components/Category/CategoryGrid';
import { useSelector } from 'react-redux';

interface Category {
  _id: string;
  icon: string;
  title: string;
  totalPrompts: number;
}

interface Prompt {
  _id: string;
  text: string;
}

interface BreadcrubLink {
  text: string;
  link: string;
}

const Prompts = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState<Category[] | []>([]);
  const [promptsList, setPromptsList] = useState<Prompt[] | []>([]);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<BreadcrubLink[]>([
    { text: 'Home', link: '/mc' },
  ]);
  const { sendRequest, isLoading, error } = useHttp();
  const user = useSelector((state: any) => state.user);

  const getPrompts = async () => {
    const reqConfig = {
      method: 'POST',
      url:
        process.env.REACT_APP_BACKEND_ADDRESS +
        '/prompt/by-subsubcategory/' +
        params.subSubCategoryId,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {
        userId: user.id,
      },
    };

    const response = await sendRequest(reqConfig);

    if (response?.response?.status === 403) {
      navigate('/');
    } else {
      setPromptsList(response.data.data.prompts.reverse());
      setBreadcrumbLinks((prev) => [
        ...prev,
        {
          text: response.data.data.categoryTitle,
          link: '/sc/' + response.data.data.category,
        },
        {
          text: response.data.data.subCategoryTitle,
          link: '/ssc/' + response.data.data.subCategory,
        },
        {
          text: response.data.data.title,
          link: '/p/' + response.data.data._id,
        },
      ]);
    }
  };

  useEffect(() => {
    getPrompts();
  }, []);

  const handleCategorySelection = (link: string) => {
    navigate(link);
  };

  const handlePromptSelection = (promptId: string) => {
    navigate(`/c/${promptId}`);
  };

  const handleResults = (result: any) => {
    setCategoryList([
      ...result.categories,
      ...result.subCategories,
      ...result.subSubCategories,
    ]);
    setPromptsList([...result.prompts]);
  };

  const handleResetSearch = () => {
    getPrompts();
    setCategoryList([]);
    setBreadcrumbLinks([{ text: 'Home', link: '/mc' }]);
  };

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      searchResult={handleResults}
      onResetSearch={handleResetSearch}
    >
      <LoadingBar loading={isLoading} />
      <CategoryGrid
        categoryList={categoryList}
        categoryChange={handleCategorySelection}
      />
      <PromptList
        promptsList={promptsList}
        onPromptSelect={handlePromptSelection}
      />
    </Layout>
  );
};

export default Prompts;
