import { createSlice } from '@reduxjs/toolkit';

const initialUserState: any = {
  address: undefined,
  accessToken: undefined,
  id: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser(state, action) {
      state.address = action.payload.address;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload.accessToken;
      state.id = action.payload.userId;
    },
    resetState(state, action) {
      state.address = undefined;
      state.accessToken = undefined;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
