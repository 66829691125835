import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Box from '@mui/material/Box';
import Conversation from '../components/Chat/Conversation';
import Input from '../components/Chat/Input';
import useHttp from '../hooks/use-http';
import { useSelector } from 'react-redux';

interface BreadcrubLink {
  text: string;
  link: string;
}
const Chat = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [chatGPTActive, setChatGPTActive] = useState(false);
  const [promptResponse, setPromptResponse] = useState('');
  const [promptSent, setPromptSent] = useState(false);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<BreadcrubLink[]>([
    { text: 'Home', link: '/mc' },
  ]);
  const { sendRequest, isLoading, error } = useHttp();
  const user = useSelector((state: any) => state.user);

  const getPrompt = async () => {
    const reqConfig = {
      method: 'POST',
      url:
        process.env.REACT_APP_BACKEND_ADDRESS +
        '/prompt/one/' +
        params.promptId,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {
        userId: user.id,
      },
    };

    const response = await sendRequest(reqConfig);
    if (response?.response?.status === 403) {
      navigate('/');
    } else {
      setSelectedPrompt(response.data.data.text);
      setChatGPTActive(response.data.data.chatGPT);
      setBreadcrumbLinks((prev) => [
        ...prev,
        {
          text: response.data.data.categoryTitle,
          link: '/sc/' + response.data.data.category,
        },
        {
          text: response.data.data.subCategoryTitle,
          link: '/ssc/' + response.data.data.subCategory,
        },
        {
          text: response.data.data.subSubCategoryTitle,
          link: '/p/' + response.data.data.subSubCategory,
        },
      ]);
    }
  };

  useEffect(() => {
    getPrompt();
  }, []);

  const handleSubmit = async () => {
    setPromptSent(true);

    const reqConfig = {
      method: 'POST',
      url: process.env.REACT_APP_BACKEND_ADDRESS + '/ai/chat-gpt/' + user.id,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {
        prompt: selectedPrompt,
        promptId: params.promptId,
      },
    };

    const response = await sendRequest(reqConfig);

    if (response.data.error) {
      setPromptResponse(response.data.error);
    } else {
      setPromptResponse(response.data.data);
    }
  };

  const handlePromptChange = (prompt: string) => {
    setSelectedPrompt(prompt);
  };

  return (
    <Layout noSearchBar breadcrumbLinks={breadcrumbLinks}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '20px',
        }}
      >
        {promptSent && <Conversation text={selectedPrompt} type='user' />}
        {promptSent && (
          <Conversation
            loading={isLoading}
            text={promptResponse}
            type='system'
          />
        )}
        {!promptSent && (
          <Input
            promptId={params.promptId}
            disabled={!chatGPTActive}
            selectedPrompt={selectedPrompt}
            onSubmit={handleSubmit}
            onPromptChange={handlePromptChange}
          />
        )}
      </Box>
    </Layout>
  );
};

export default Chat;
