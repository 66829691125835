import { useState, useCallback } from 'react';

import axios from 'axios';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [status, setStatus] = useState<number>();

  const sendRequest = useCallback(async (requestConfig: object) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios(requestConfig);

      setStatus(response.status);
      if (response.status !== 200) {
        throw new Error('Request failed!');
      }
      setIsLoading(false);
      return response;
    } catch (err: any) {
      setIsLoading(false);
      setError(
        err.response ? err.response.data.error : 'Some thing went wrong'
      );

      setStatus(err.response.status);

      return err;
    }
  }, []);

  return {
    isLoading,
    error,
    status,
    sendRequest,
  };
};

export default useHttp;
