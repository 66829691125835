import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  children?: ReactNode;
  onClick?: () => void;
}

const TextDisplay: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      onClick={props.onClick}
      sx={{
        padding: isMobile ? '10px' : '28px',
        backgroundColor: '#323336',
        borderRadius: '5px',
        fontSize: isMobile ? '16px' : '24px',
        fontWeight: '400',
        color: '#D1D5DC',
        lineHeight: '29px',
        whiteSpace: 'pre-wrap',
        '&:hover': {
          backgroundColor: '#323336cf',
        },
      }}
      variant='subtitle1'
    >
      {props.children}
    </Typography>
  );
};

export default TextDisplay;
