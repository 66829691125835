import Box from '@mui/material/Box';
import Header from '../components/Home/Header';
import HeroSection from '../components/Home/HeroSection';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../store/user';
import useHttp from '../hooks/use-http';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendRequest, isLoading, error } = useHttp();

  const handleConnect = async (address: any) => {
    dispatch(userActions.setUser({ address }));

    const reqConfig = {
      method: 'GET',
      url: process.env.REACT_APP_BACKEND_ADDRESS + '/user/' + address,
    };

    const response = await sendRequest(reqConfig);

    if (response.data.data) {
      const token = response.data.data.accessToken;
      const userId = response.data.data._id;
      dispatch(userActions.setAccessToken({ accessToken: token, userId }));

      const redirectUrl = sessionStorage.getItem('redirectUrl');
      if (redirectUrl) {
        sessionStorage.removeItem('redirectUrl'); // Clean up the stored URL
        window.location.href = redirectUrl; // Redirect to the stored URL
      } else {
        navigate('/mc');
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          py: isMobile ? '10px' : '20px',
          px: isMobile ? '10px' : '40px',
          height: isMobile ? '100%' : '100vh',
        }}
      >
        <Header
          alert={Boolean(error)}
          alertMessage={error}
          home
          onConnect={handleConnect}
          loading={isLoading}
        />
        <HeroSection />
      </Box>
      <Box
        sx={{
          position: isMobile ? 'static' : 'fixed',
          left: '0',
          bottom: '0',
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <img
          style={{
            marginTop: '-70px',
            height: isMobile ? '200px' : isTablet ? '300px' : '400px',
          }}
          alt='dog nft'
          src='/img/dog_nft.svg'
        />
      </Box>
    </>
  );
};

export default Home;
