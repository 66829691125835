import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Category from './pages/Category';
import SubCategory from './pages/SubCategory';
import SubSubCategory from './pages/SubSubCategory';
import Prompts from './pages/Prompts';
import Chat from './pages/Chat';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { useWeb3ModalTheme } from '@web3modal/react';

const chains = [polygon];
const projectId = '90b73fddec47187216fb3e898821f58e';

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const { setTheme } = useWeb3ModalTheme();

  setTheme({
    themeMode: 'dark',
    themeVariables: {
      '--w3m-font-family': 'Roboto, sans-serif',
      '--w3m-accent-color': '#B33A78',
      // ...
    },
  });

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/mc' element={<Category />} />
          <Route path='/sc/:categoryId' element={<SubCategory />} />
          <Route path='/ssc/:subCategoryId' element={<SubSubCategory />} />
          <Route path='/p/:subSubCategoryId' element={<Prompts />} />
          <Route path='/c/:promptId' element={<Chat />} />
        </Routes>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
