import Grid from '@mui/material/Unstable_Grid2';
import Card from './Card';

interface Category {
  _id: string;
  title: string;
  icon: string;
  totalPrompts: number;
  subCategories?: any;
  subSubCategories?: any;
  prompts?: any;
}

interface Props {
  categoryList: Category[];
  categoryChange: (id: string) => void;
}

const CategoryGrid: React.FC<Props> = (props) => {
  const getLink = (category: Category) => {
    if (category.subCategories) {
      return '/sc/' + category._id;
    } else if (category.subSubCategories) {
      return '/ssc/' + category._id;
    } else {
      return '/p/' + category._id;
    }
  };

  return (
    <Grid sx={{ mb: '10px' }} container spacing={2}>
      {props.categoryList.map((category) => (
        <Grid
          key={category._id}
          xs={12}
          md={
            category.title.length < 20 ? 4 : category.title.length < 25 ? 6 : 12
          }
        >
          <Card
            text={category.title}
            numberOfPrompts={category.totalPrompts}
            icon={category.icon}
            onClick={() => props.categoryChange(getLink(category))}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryGrid;
