import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user';
import { Web3Button } from '@web3modal/react';
import { useAccount } from 'wagmi';

interface Props {
  home?: boolean;
  alert?: boolean;
  alertMessage?: string;
  wallet?: string;
  disabled?: boolean;
  loading?: boolean;
  onConnect?: (address: any) => void;
}

const Header: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const account = useAccount();

  const handleLogout = () => {
    navigate('/');
    dispatch(userActions.resetState({}));
  };

  useEffect(() => {
    if (account.isConnected) {
      props.onConnect && props.onConnect(account.address);
    } else {
      handleLogout();
    }
  }, [account.address]);

  return (
    <>
      {props.alert && (
        <Alert sx={{ mb: '1rem' }} severity='info'>
          {props.alertMessage}
        </Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <img
          onClick={() => navigate('/')}
          alt='underdog logo'
          style={{ maxWidth: isMobile ? '155px' : '330px', cursor: 'pointer' }}
          src='/img/main_logo.png'
        />
        <Web3Button />
      </Box>
    </>
  );
};

export default Header;
