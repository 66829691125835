import Box from '@mui/material/Box';
import TextDisplay from '../../components/UI/TextDisplay';

interface Prompt {
  _id: string;
  text: string;
}

interface Props {
  promptsList: Prompt[];
  onPromptSelect: (prompt: string) => void;
}

const PromptList: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {props.promptsList.map((prompt) => (
        <TextDisplay
          key={prompt._id}
          onClick={() => props.onPromptSelect(prompt._id)}
        >
          {prompt.text}
        </TextDisplay>
      ))}
    </Box>
  );
};

export default PromptList;
