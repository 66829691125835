import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  loading: boolean;
}

const LoadingBar: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        display: props.loading ? 'flex' : 'none',
        justifyContent: 'center',
        mb: '1rem',
      }}
    >
      <CircularProgress
        sx={{
          mx: 'auto',
          color: '#D1603C',
        }}
      />
    </Box>
  );
};

export default LoadingBar;
