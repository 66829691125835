import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextDisplay from '../UI/TextDisplay';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Comment } from 'react-loader-spinner';

interface Props {
  text: string;
  img?: string;
  type: 'system' | 'user';
  loading?: boolean;
}

const Conversation: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: isMobile ? '10px' : props.loading ? '0' : '20px',
          alignItems: 'flex-start',
        }}
      >
        {props.type === 'user' && <img src='/img/dog_portrate.svg' />}
        {props.type === 'system' && (
          <Typography
            sx={{
              height: '2rem',
              width: '2rem',
              borderRadius: '999px',
              backgroundColor: '#484A50',
              fontSize: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            UC
          </Typography>
        )}
        <Comment
          visible={props.loading ? true : false}
          height='80'
          width='80'
          ariaLabel='comment-loading'
          wrapperStyle={{
            height: '3.8rem',
          }}
          wrapperClass='comment-wrapper'
          color='#fff'
          backgroundColor='#000'
        />
        {props.text && <TextDisplay>{props.text}</TextDisplay>}
      </Box>
    </>
  );
};

export default Conversation;
