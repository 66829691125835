import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  text: string;
  icon: string;
  numberOfPrompts: number;
  onClick?: () => void;
}

const Card: React.FC<Props> = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return (
    <Box
      onClick={props.onClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundImage: 'linear-gradient(to right, #D1603C, #981AAC)',
        minHeight: '125px',
        px: '20px',
        borderRadius: '25px',
        '&:hover': {
          backgroundImage: 'linear-gradient(to right, #d15f3cd4, #991aacc6)',
        },
      }}
    >
      <Box
        sx={{
          height: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <img alt='message icon' src='/img/message.svg' />
        <Typography
          sx={{ alignSelf: 'end', fontSize: '16px', lineHeight: '20px' }}
          variant='subtitle1'
        >
          {props.numberOfPrompts} Prompts
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          mt: '-20px',
        }}
      >
        <Box
          sx={{
            height: '90px',
            width: '90px',
            flexShrink: 0,
            backgroundColor: '#D1D5DC',
            borderRadius: '999px',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
          }}
        >
          <img alt='icon' style={{ width: '52px' }} src='/img/ChatGPT.svg' />
        </Box>
        <Typography
          variant='subtitle1'
          sx={{
            fontSize: isMobile ? '24px' : isTablet ? '28px' : '34px',
            fontWeight: '600',
            lineHeight: isMobile ? '29px' : isTablet ? '31px' : '35px',
          }}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Card;
