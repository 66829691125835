import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Web3Button } from '@web3modal/react';

interface Props {
  alert?: boolean;
  wallet?: string;
  onConnect?: () => void;
}

const HeroSection: React.FC<Props> = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: '10',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: isMobile ? '0' : '20px',
        marginTop: isMobile ? '0' : '7%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: isMobile ? '0' : '10px',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontSize: '32px',
            fontFamily: "'Press Start 2P', cursive",
          }}
          variant='h1'
          gutterBottom
        >
          AI Prompts For
        </Typography>
        <Typography
          sx={{
            color: 'white',
            fontSize: '32px',
            fontFamily: "'Press Start 2P', cursive",
            backgroundImage: 'linear-gradient(to right, #D1603C, #981AAC)',
            padding: '10px',
          }}
          variant='h1'
          gutterBottom
        >
          Underdogs
        </Typography>
      </Box>
      <Typography
        sx={{
          color: '#D1D5DC',
          fontSize: isMobile ? '18px' : isTablet ? '22px' : '28px',
          fontWeight: '200',
          lineHeight: isMobile ? '26px' : '38px',
          textAlign: 'justify',
          margin: '20px 0',
          maxWidth: '1100px',
        }}
        variant='subtitle1'
        gutterBottom
      >
        The ultimate creativity companion that levels the playing field for
        everyone with a thirst for knowledge and a desire to grow. This
        versatile app boasts a comprehensive library of AI prompts spanning a
        wide array of subjects. With AI Prompts for Underdogs, you'll be
        well-equipped to transform your aspirations into achievements, giving
        you the edge you need to break through barriers and make your mark on
        the world.
      </Typography>
      <Web3Button />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '15px 0px',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? '16px' : '24px',
            fontWeight: '200',
          }}
          variant='subtitle1'
        >
          You need to own at least one Underdog Club NFT to access this tool.
        </Typography>
        <Typography
          sx={{ fontSize: isMobile ? '16px' : '24px' }}
          variant='subtitle1'
        >
          Get your NFT at{' '}
          <a
            href='https://underdogclub.com'
            style={{
              position: 'relative',
              color: '#D1603C',
              textDecoration: 'underline',
              zIndex: '100',
            }}
          >
            https://underdogclub.com
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroSection;
