import { ReactNode, useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../Home/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import useHttp from '../../hooks/use-http';
import SearchField from '../UI/SearchField';
import LoadingBar from '../Category/LoadingBar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Pagination from '@mui/material/Pagination';
import { useSelector } from 'react-redux';

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: '#fff',
  },
}));

interface BreadcrubLink {
  text: string;
  link: string;
}

interface Props {
  children?: ReactNode;
  breadcrumbLinks: BreadcrubLink[];
  noSearchBar?: boolean;
  searchResult?: (r: any) => void;
  onResetSearch?: () => void;
}

const Layout: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { sendRequest, isLoading, error } = useHttp();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!user.accessToken) {
      const currentUrl = window.location.href;
      sessionStorage.setItem('redirectUrl', currentUrl);
      navigate('/');
    }
  }, []);

  const getSearchResults = async (
    term: string | undefined,
    pageNumber: number = 1
  ) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const reqConfig = {
      method: 'POST',
      url: process.env.REACT_APP_BACKEND_ADDRESS + '/search',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {
        searchTerm: term ? term : search,
        pageNumber,
      },
    };

    const response = await sendRequest(reqConfig);
    props.searchResult?.(response.data.data);
    setTotalPages(response.data.data.totalPages);
  };

  const handleSearch = async (term: string) => {
    setSearch(term);

    if (term === '') {
      props.onResetSearch && props.onResetSearch();
      setTotalPages(0);
    } else {
      await getSearchResults(term);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    getSearchResults(undefined, page);
  };

  const handleBreadcrumbClick = (link: string) => {
    const currentRoute = location.pathname;

    if (currentRoute === link) {
      window.location.reload();
    } else {
      navigate(link);
    }
  };

  if (!user.accessToken) return null;

  return (
    <Box
      sx={{ py: isMobile ? '10px' : '20px', px: isMobile ? '10px' : '40px' }}
    >
      <Header />
      <Breadcrumbs
        sx={{ color: '#D1D5DC', marginBottom: '20px' }}
        aria-label='breadcrumb'
      >
        {props.breadcrumbLinks.map((nav, index) => (
          <Typography
            key={index}
            variant='subtitle1'
            onClick={() => handleBreadcrumbClick(nav.link)}
            sx={{
              color: '#D1D5DC',
              cursor: 'pointer',
              fontSize: isMobile ? '16px' : '24px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {nav.text}
          </Typography>
        ))}
      </Breadcrumbs>
      <SearchField
        inVisible={props.noSearchBar}
        onSearch={handleSearch}
        loading={isLoading}
      />
      <LoadingBar loading={isLoading} />
      {props.children}
      <Stack
        sx={{ display: totalPages > 0 ? 'flex' : 'none', mt: '1rem' }}
        spacing={2}
      >
        <StyledPagination
          sx={{ alignSelf: 'center' }}
          onChange={handlePageChange}
          color='secondary'
          count={totalPages}
        />
      </Stack>
    </Box>
  );
};

export default Layout;
