import { useState } from 'react';
import Box from '@mui/material/Box';
import CustomButton from './Button';

interface Props {
  onSearch: (term: string) => void;
  loading?: boolean;
  inVisible?: boolean;
}

const SearchField: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const handleSearch = () => {
    props.onSearch(search);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        display: props.inVisible ? 'none' : 'flex',
        alignItems: 'center',
        mb: '20px',
        backgroundColor: '#D1D5DC',
        borderRadius: '999px',
        px: '20px',
        py: '5px',
      }}
    >
      <input
        style={{
          backgroundColor: '#D1D5DC',
          flex: 1,
          border: 'none',
          fontSize: '18px',
          outline: 'none',
          minWidth: '20px',
        }}
        placeholder='Search'
        onKeyDown={handleKeyDown}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <CustomButton
        loading={props.loading}
        onClick={handleSearch}
        colorGradient='horizontal'
      >
        Search
      </CustomButton>
    </Box>
  );
};

export default SearchField;
